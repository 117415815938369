@charset 'UTF-8';
@import "/Users/alivevn/Website/vhost/kanal-reform/src/scss/utility/_media-queries.scss";
@import "/Users/alivevn/Website/vhost/kanal-reform/src/scss/utility/_mixin.scss";


#page404 {
  .taC { display: block; font-size: 20px; line-height: 30px; letter-spacing: 3px; text-align: center; margin: 20px 0 0 0;
    
  }
  .cont{
    p{
      @include SP{
        font-size: 15px;
      }
    }
    padding: 30px 20px 120px;
  }
}
